.registerContainer {
  /* max-width: 1300px; */
  background-color: pink;
  margin: auto;
  /* padding: 3rem 0; */
  /* height: 100vh; */
  background-image: url('../assets/bgimage.png'); /* Adjust the path if needed */
  background-size: cover; /* Adjust this based on your needs */
  background-position: center; /* Centers the image */
  background-attachment: fixed; /* Keeps the image static while scrolling */
  background-repeat: no-repeat; /* Ensures the image doesn’t repeat */
  /* height: 100vh; Adjust height as needed */
  /* width: 100%; Full width */
  padding: 5rem;
}
.registerimg {
  width: 100%;
  height: 24rem;
  object-fit: cover;
}
.name-category {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.vogue-star-form {
  max-width: 1300px;
  margin: 0 auto;
  padding: 1.5rem;
  padding-right:1.5rem ;
  background-color: rgba(255, 255, 255, 0.676);
  box-shadow: 3px 6px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.form-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #372525;
  font-weight: bold;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;
}
.full-width {
  grid-column: 1 / -1; /* This makes the div span across both columns */
}
.form-labell {
  color: #bc1522;
}
.form-label {
  width: 8rem;
  display: block;
  margin-bottom: 0.5rem;
  color: #bc1522;
}
.smaller-space {
  flex: 1; /* This div will take up one-third of the space */
}
.flex-child {
  flex: 2; /* Adjust this value to control the proportions */
}

.form-field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.form-select,
.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #bc1522;
  border-radius: 0.25rem;
}

.dropzone {
  border: 2px dashed #bc1522;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  background-color: white;
}
.crop-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.crop-container button:hover {
  background-color: #45a049;
}

.crop-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* .crop-modal {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.crop-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
}

.crop-button {
  margin-top: 1rem;
  z-index: 1000;
  width: 10rem;
  height: 10rem;
  background-color: blue;
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
} */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #333;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions button:first-child {
  background-color: pink;
  color: white;
}

.modal-actions button:last-child {
  background-color: #bc1522;
  color: white;
}

.modal-actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.size-selector {
  display: flex;
  gap: 1rem;
}

.size-button {
  font-size: 13px;
  padding: 0.5rem 0.8rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background-color: pink;
  cursor: pointer;
}

.size-button.selected {
  background-color: #bc1522;
  color: white;
}

.submit-button {
  width: 100%;
  margin-top: 1.5rem;
  background-color: #bc1522;
  color: white;
  padding: 0.8rem 0.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
}
.submit-button:hover {
  background-color: #bc1523c2;
  color: white;
}
.photos {
  width: 100%;
  /* display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; */
}
.photo-title {
  color: #bc1522;
}
.drop-container {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: flex-start;
  padding: 2rem 0;
}
.dropzone.form-input{
    width: 15rem;
}
/* .dropzone.idproof {
    width: auto !important;
} */
.drop-container > .form-field {
  flex-direction: column;
  gap: 1rem;
}

.text-input {
  width: 95%;
  padding: 0.5rem;
  border: 1px solid #bc1522;
  border-radius: 0.25rem;
  height: 5rem;
}
.dropzone.idproof {
  width: 90%;
}
.form-label.day {
  width: auto;
}
.black {
  color: black;
}
.pinktxt {
  color: #bc1522;
}
.qr-code-section {
  width: 100%;
}

.qr-code-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.qr-code-image {
  max-width: 100%;
  height: auto;
  max-height: 30vw; /* Adjust this value as needed */
}


@media (max-width: 1025px) {
  .drop-container{
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-self: start;
  }
  .drop-container > .form-field {
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
 
  .vogue-star-form {
    max-width: 100vw;
  }
  .registerContainer{
    padding: 4rem 3rem;
  }
 

}
@media (max-width: 768px) {
  .registerContainer{
    padding: 1.5rem;
  }
  .form-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }
  
  .form-label {
    width: auto;
  }
  .form-select,
  .form-input {
      width: 95%;
  
  }
  .form-field{
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }
  .vogue-star-form{
    padding: 1rem;
  }
  .qr-code-title {
    font-size: 1rem;
}

.qr-code-image {
    max-height: 80vw; /* Adjust this value for smaller screens */
}
}
