@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  background-color: #ff9bbd;
  font-family: 'Montserrat', sans-serif;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Darker background */
  backdrop-filter: blur(8px); /* Blur background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* High z-index */
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.3s ease-in-out; /* Smooth fade-in animation */
}

.google-signin-btn {
  background-color: #4285f4;
  color: white;
  text-align: center;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.google-signin-btn:hover {
  background-color: #357ae8;
}

.google-icon {
  margin-right: 10px;
  font-size: 20px;
}

/* Animation for modal appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.mobile_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(12px); /* Blur background */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.mobile_modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.mobile-icon {
  margin-right: 0.5rem;
  color: #888;
}

.mobile-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
}

.submit-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}
.error-message {
  color: red;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.submit-btn:hover {
  background-color: #357ae8;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
  }
  .mobile_modal-content {
    width: 75%;
  }

  .mobile-input {
    font-size: 0.9rem;
  }

  .submit-btn {
    padding: 0.5rem 1rem;
  }
}
