.ourteams {
  position: relative;
}
.slider-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  /* overflow-x: hidden; */
  position: relative;
}
.pinkoutline {
  position: absolute;
  width: 15%;
  right: 02rem;
  top: 0;
}
.heading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 68px;
  font-weight: 800;
  color: #fff;
}

.slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: max-content;
  transition: transform 0.3s ease;
  overflow: visible;
  max-width: 1300px;
  margin: auto;
}

.slide_card {
  overflow: visible;
  width: 70%;
  height: 70vh;
  background-color: #7ed1df;
  transform: translateX(-10%);
  display: flex;
  gap: 4rem;
  border-radius: 20px;
}

.headers {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: max-content;
  font-size: 1rem;
}
.teams-carousel {
  width: 80%;
  margin: auto;
}
.headers h1 {
  padding: 0;
  margin: 0;
  color: #000000;
}
.headers h3 {
  padding: 0;
  margin: 0;
}

.text_content p {
  width: 100%;

  font-size: 14px;
}

.text_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3%;
}

.image {
  width: 30%;
  position: relative;
  top: 2rem;
  left: -3rem;
}

.image img {
  height: 65vh;
}

.slide h3 {
  font-size: 24px;
  color: #333333;
}

.dots {
  bottom: -30px;
  color: white;
}

.dots li button:before {
  font-size: 12px;
  color: #ffffff;
}

.dots li.active button:before {
  color: #007bff;
}

.prev,
.next {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: none;
}

.prev {
  left: -40px;
}

.next {
  right: -40px;
}

.prev:before,
.next:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #333333;
}

.prev:hover:before,
.next:hover:before {
  opacity: 1;
  color: #007bff;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #ffffff !important;
}

/* Responsive Styles */
@media (max-width: 1025px) {
  .heading {
    font-size: 80px;
  }
  .slide {
    height: max-content;
  }
  .slide_card {
    /* height: 30vh; */
    overflow: visible;
    margin-top: 30vh;
    position: relative;
    left: 8%;
    height: max-content;
  }
  /* .text_content {
    width: 100%;
  } */
  .image {
    top: 4rem;
    position: absolute;
    width: 100%;
    height: 65%;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .image img {
    height: 250px;
    aspect-ratio: 1/1;
    top: 0;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .headers {
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    gap: 0;
    h1 {
      margin: 0;
      text-align: center;
    }
  }

  .text_content {
    margin-top: 5rem;
    width: 100%;
    margin-top: 60px;
    padding: 20px;
    p {
      margin-top: 20px !important;
      margin: 0;
      width: 100%;
      font-size: 12px;
    }
  }
}

@media (max-width: 768px) {
  .heading {
    font-size: 36px;
  }
  .swiper {
    overflow: visible !important ;
  }
  .swiper-button-next {
    right: -10px;
    margin: 0rem;
  }
  .swiper-button-prev {
    margin: 0rem;
    left: -25px;
  }
}

@media (max-width: 500px) {
  .slide_card {
    width: 90vw;
  }
  .image img {
    height: 250px;
    aspect-ratio: 1/1;
    object-fit: contain;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image {
    align-items: flex-end;
  }
  .text_content {
    margin-top: 7rem;
    width: 100%;
  }
  .pinkoutline {
    width: 28%;
    right: 1.5rem;
    top: 8rem;
  }
  .headers h1,
  .headers h3 {
    text-align: center;
    font-size: 20px;
  }
}
