.About_us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30vh;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */

  /* position: relative; */
}
.about_main {
  z-index: 10;
  width: 100%;
  padding: 30px 0px;
}
.about_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  margin: auto;
  margin-top: -25vh;
  padding: 20px;
  /* background-color: #ff9bbd; */
}
.About_us h2 {
  font-size: 68px;
  margin: 0;
}
.About_us p {
  text-align: center;
  font-size: 24px;
  color: #8d311e;
  height: 10vh;

  margin: 0;
  padding: 20px 50px;
}
@media (max-width: 1024px) {
  .About_us p {
    font-size: 20px;
  }
}
@media (max-width: 900px) {
  .about_content {
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .About_us h2 {
    font-size: 36px;
  }
  .About_us p {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  /* .about_main{
  height: 30vh;
 } */
  .about_content {
    padding: 0px 20px;
    margin-top: 0;
  }
  .About_us h2 {
    font-size: 36px;
  }
  .About_us p {
    margin-top: 20px;
    font-size: 14px;
    padding: 0px;
  }
}
