.updatescontainer {
  width: 100%;
  /* height: 100vh; */
  padding-bottom: 5rem;
  z-index: 50;
  position: relative;
}
.yellowflwr {
  position: absolute;
  top: 0%;
  left: 1rem;
  width: 17%;
  z-index: 10;
}
.pinkflwru {
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  width: 12%;
  z-index: 10;
}
.updatetitle {
  font-weight: 800;
  text-align: center;
  color: white;
  font-size: 68px;
}
.carousel {
  width: 100vw;
}

.updateimg {
  width: 30vw;
  aspect-ratio: 1/1;
  max-width: 1300px;
  border-radius: 2.5rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* Hide default Swiper navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(255, 255, 255, 0.802);
  border-radius: 25px;
  padding: 10px;
  height: 0.5rem;
  font-weight: 900;
  margin: 1rem;
}
.swiper-pagination-bullet-active {
  background-color: #bc1522 !important;
}
/* Ensure the carousel container has a relative positioning */
.carousel {
  position: relative;
}
@media (max-width: 1025px) {
  .updateimg {
    width: 70vw;
  }
  .pinkflwru {
    bottom: 0rem;
    right: 2rem;
    width: 15%;
  }
}
@media (max-width: 768px) {
  .updateimg {
    width: 80vw;
    border-radius: 1.5rem;
  }
  .imgcontainer {
    width: 100vw;
  }
  .updatetitle {
    font-size: 36px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    margin: 0rem;
  }
}
@media (max-width: 500px) {
  .yellowflwr {
    top: -25%;
    left: -4rem;
    width: 35%;
    z-index: 0;
  }
  .pinkflwru {
    bottom: 0rem;
    right: 2rem;
    width: 30%;
    z-index: 10;
  }
}
