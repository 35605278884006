.Contact_us {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* background-color: #ff9bbd; */
  margin: 0;
  padding: 0;
}
.Contact_us .Newsletter {
  width: 100%;
  display: flex;
  z-index: 50;

  justify-content: center;
  align-items: center;
  padding-bottom: 60px;
  /* background-color: #ff9bbd; */
}
.Contact_us .Newsletter .news_content {
  max-width: 1300px;
  z-index: 50;

  margin-top: 50px;
  border-radius: 28px;
  background-color: #7ed1df;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.266);
  padding: 80px 120px;
}

.Contact_us .Newsletter .news_content h3 {
  text-align: center;
  font-size: 55px;
  margin: 0;
  color: white;
}
.Contact_us .Newsletter .news_content p {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.email {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.email input {
  width: 60%;
  height: 40px;

  color: #544d4d;
  border: 1px solid rgb(210, 207, 207);
  border-radius: 5px;
  font-size: 20px;
  font-weight: 400;
  padding-left: 18px;
}
.email input::placeholder {
  color: #949494;
}
.email button {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  font-size: 30px;
  border: 0;
  color: white;
  background-color: #ff9bbd;
  transition: all 0.3s;
}
.email button:hover {
  border: 2px solid white;
}
.contact_main {
  width: 100%;
  z-index: 50;

  /* background-color: #ff9bbd; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact_main .contact_content {
  padding: 50px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contact_main .contact_content .contact_para {
  background-color: #bc1522;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  width: 40%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_para p {
  margin: 0;
  font-size: 40px;
  color: white;
  font-weight: 800;
}
.icons {
  margin-right: 100px;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact_content ul {
  list-style-type: none;
  width: 300px;
}
.icons li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}
.contact_content ul div {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #ff9bbd;
  background-color: white;
}
.contact_content ul li {
  color: white;
  font-size: 20px;
}

.icons li div {
  min-width: 35px; 
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Contact_us .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.Contact_us .modal_content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 300px;
  text-align: center;
  border-radius: 10px;
}

.Contact_us .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.Contact_us .close:hover,
.Contact_us .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .Contact_us .Newsletter {
    width: 70%;
  }

  .Contact_us .Newsletter .news_content {
    padding: 80px 40px;
  }
}

@media (max-width: 768px) {
  .Contact_us .Newsletter {
    width: 85%;
  }
  .Contact_us .Newsletter .news_content {
    padding: 50px 40px;
  }
  .contact_main .contact_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .contact_main .contact_content {
    padding: 0px;
  }
  .Contact_us .Newsletter .news_content h3 {
    font-size: 36px;
  }
  .contact_main .contact_content .contact_para {
    border-radius: 50px;
    height: 60px;
  }
  .Contact_us .Newsletter .news_content p {
    font-size: 18px;
  }
  .contact_main .contact_content .contact_para {
    background-color: transparent;
    width: 100%;
  }
  .contact_para p {
    font-size: 36px;
    color: black;
  }
  .icons {
    margin: 0;
  }
  .email input {
    font-size: 12px;
  }
  .email input::placeholder {
    color: #949494;
    font-size: 12px;
    padding: 0px;
  }
}

@media (max-width: 500px) {
  .Contact_us .Newsletter {
    width: 90%;
  }
  .Contact_us .Newsletter .news_content h3 {
    font-size: 24px;
  }
  .Contact_us .Newsletter .news_content p {
    font-size: 14px;
  }
  .contact_main .contact_content .contact_para {
    border-radius: 50px;
    height: 40px;
  }

  .email input {
    font-size: 15px;
  }
}
