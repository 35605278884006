.navbar_main {
  width: 100%;
  height: 10vh;
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  background-image: linear-gradient(147deg, #f9fcff82 0%, #dee4ead4 74%);
  /* background: linear-gradient(to bottom, #ffffffe2, #fff0f5ba, #ffd1dcd8); */
  /* background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      /* rgb(223, 163, 183) 70%,   rgba(255, 209, 220, 0.8) 70%,
      rgba(255, 209, 220, 0.8) 85%,
      rgba(255, 209, 220, 0) 100%
    ),
    radial-gradient(
      ellipse at bottom,
      rgba(255, 209, 220, 0.8) 0%,
      rgba(255, 255, 255, 0) 70%
    ); */
  background-size:
    100% 100%,
    100% 15px;
  background-position: top, bottom;
  background-repeat: no-repeat;
  padding-bottom: 8px;
}
.navbar {
  max-width: 1300px;
  margin: auto;
}
.navbar-links {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  position: relative;
  left: -0.8rem;
}

.navbar-links li {
  list-style-type: none;
  background-color: #bc1522;
  padding: 0.6rem 1rem;
  transition: all 0.3s ease-in;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
  color: #fff;
  padding: 10px;
  transition: transform 0.3s ease;
}

.navbar-links li:hover {
  background-color: #bc152397;
}

@media (max-width: 900px) {
  .navbar_main {
    height: 6vh;
  }
  .navbar-toggle {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1001;
    color: #af174a;
  }
  .navbar_main {
    background-image: linear-gradient(147deg, #f9fcff82 0%, #dee4ead4 74%);
  }
  .icon-transition {
    transition: transform 0.3s ease-in-out;
  }

  .icon-transition.cross {
    transform: rotate(180deg);
    color: white;
  }

  .navbar-links {
    position: fixed;
    top: 0;
    right: 0; /* Changed from left: 0 to right: 0 */
    width: 100%;
    height: 105vh;
    background-color: rgba(188, 21, 34, 0.95);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1rem;
    transform: translateX(100%); /* Changed from -100% to 100% */
    transition: transform 0.3s ease-in-out;
  }

  .navbar-links.active {
    transform: translateX(-5%);
  }

  .navbar-links li {
    margin: 1rem 0;
    opacity: 0;
    transform: translateX(
      20px
    ); /* Changed from translateY to translateX for right-side appearance */
    transition:
      opacity 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
  }

  .navbar-links.active li {
    opacity: 1;
    transform: translateX(0);
  }

  .navbar-links.active li:nth-child(1) {
    transition-delay: 0.1s;
  }
  .navbar-links.active li:nth-child(2) {
    transition-delay: 0.2s;
  }
  .navbar-links.active li:nth-child(3) {
    transition-delay: 0.3s;
  }
  .navbar-links.active li:nth-child(4) {
    transition-delay: 0.4s;
  }
  .navbar-links.active li:nth-child(5) {
    transition-delay: 0.5s;
  }
  .navbar-links.active li:nth-child(6) {
    transition-delay: 0.6s;
  }

  .navbar-links li {
    background-color: transparent;
    padding: 0.8rem 1.5rem;
  }

  .navbar-links li:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .navbar-links a {
    font-size: 1.2rem;
  }
}
/* Button Animation CSS */

.button-animation {
  position: relative;
  display: inline-block;
  /* padding: 20px 30px; */
  color: #ff9bdd;
  text-transform: uppercase;
  overflow: hidden;
  letter-spacing: 4px;
  transition: 0.5s;
}

.button-animation span {
  position: absolute;
  display: block;
}

.button-animation span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, transparent, white);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.button-animation span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(180deg, transparent, white);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.button-animation span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 4px;
  background: linear-gradient(270deg, transparent, white);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.button-animation span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(360deg, transparent, white);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.button-animation:hover {
  background-color: #ff9bdd;
  color: #af174a;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}
