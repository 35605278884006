.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-wrapper {
  position: relative;
  width: 90%;
  margin: 10px;
  padding: 5px;
  background: linear-gradient(to bottom, rgb(161, 161, 161), white);
}

.video-wrapper::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  border-radius: inherit;
  background: linear-gradient(
    to bottom,
    rgb(161, 161, 161),
    white
  ); /* Gradient border */
}
.joinus {
  padding: 80px 0px;
}
.iframe_videos {
  width: 100%;
  height: 500px;
  border: none; /* Remove default iframe border */
  display: block;
}

.video-block {
  text-align: center;
  max-width: 1300px;
  width: 1000px;
  margin-top: 30px;
  border-radius: 28px;
  background-color: #7ed1df;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.266);
  padding-top: 20px;
  padding: 0 80px 80px 80px;
  position: relative;
}
@media (max-width: 768px) {
  .video-block {
    padding: 0px 20px 20px 20px;
    width: 350px;
  }
}
